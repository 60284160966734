import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  updateProfile,
  updatePassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  getIdToken,
  getIdTokenResult,
  User,
  Unsubscribe,
} from 'firebase/auth';

import { auth } from './firebaseInit'; // Importing the `auth` instance
import utils from '../utils/utils';

function register(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(registeredUser => registeredUser)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function login(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
    .then(registeredUser => registeredUser)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function logout() {
  return signOut(auth)
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function getUserToken() {
  const currentUser = getCurrentUser();
  if (!currentUser) {
    throw new Error('No user is currently logged in.');
  }
  return getIdToken(currentUser, true)
    .then(idToken => idToken)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function getUserRoles() {
  const currentUser = getCurrentUser();
  if (currentUser) {
    return getIdTokenResult(currentUser)
      .then(idTokenResult => {
        let roles: string[] = [];
        utils.debugLog('idTokenResult=' + JSON.stringify(idTokenResult, null, 2));

        if (idTokenResult && idTokenResult.claims) {
          if (idTokenResult.claims.admin) {
            roles.push('admin');
          }
          if (idTokenResult.claims.developer) {
            roles.push('developer');
          }
        }
        return roles;
      })
      .catch(error => {
        console.error('GFB Error = ' + JSON.stringify(error, null, 2));
        throw error;
      });
  } else {
    console.error('FB - No user is logged in!');
    return Promise.resolve([]);
  }
}

function isUserAdmin() {
  return getUserRoles().then(roles => roles.includes('admin'));
}

function isLoggedIn() {
  return !!auth.currentUser;
}

function getCurrentUser(): User {
  if (!auth.currentUser) {
    throw new Error('No user is currently logged in.');
  }
  return auth.currentUser;
}

function updateDisplayName(displayName: string) {
  const user = getCurrentUser();
  return updateProfile(user, { displayName })
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function updateUserPhoto(photoURL: string) {
  const user = getCurrentUser();
  return updateProfile(user, { photoURL })
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function onAuthStateChanged(callback: (user: User | null) => void): Unsubscribe {
  return firebaseOnAuthStateChanged(auth, callback);
}

function changePassword(newPassword: string) {
  const user = getCurrentUser();
  return updatePassword(user, newPassword)
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function sendResetPasswordEmail(email: string): Promise<boolean> {
  return sendPasswordResetEmail(auth, email)
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function resendEmailVerification() {
  const user = getCurrentUser();
  return sendEmailVerification(user)
    .then(() => true)
    .catch(error => {
      console.error('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function screenState() {
  const DEFAULT_STATE = {
    sidebar: {
      dashboard: true,
      adminSessions: false,
      adminUsers: false,
      adminJobs: false,
    },
  };

  return getUserRoles().then(roles => {
    let result = DEFAULT_STATE;
    roles.forEach(role => {
      switch (role) {
        case 'admin':
          result.sidebar.adminSessions = true;
          result.sidebar.adminUsers = true;
          result.sidebar.adminJobs = true;
          break;
        case 'developer':
          break;
        case 'partner':
          break;
        default:
        // Leave default
      }
    });
    utils.debugLog(`FB-screenState-getUserRoles - result=${utils.convertToString(result)}`);
    return result;
  });
}

export default {
  register,
  login,
  logout,
  getUserToken,
  getUserRoles,
  isUserAdmin,
  isLoggedIn,
  getCurrentUser,
  updateDisplayName,
  updateUserPhoto,
  onAuthStateChanged,
  changePassword,
  sendResetPasswordEmail,
  resendEmailVerification,
  screenState,
};
