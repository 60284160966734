import json_stringify_safe from 'json-stringify-safe';

function isDebug() {
  return typeof Storage !== 'undefined' && localStorage.getItem('dd');
}

function debugLog(str: string) {
  if (isDebug()) {
    console.log(str);
  }
}

function consoleError(str: string) {
  console.error(str);
}

// function setCache(key,value,forSessionStorage) {
//   try {

//     if(typeof Storage !== "undefined") {
//       let item=JSON.stringify({
//         value: value,
//         timestamp: new Date().getTime(),
//         type: typeof(value),
//       });
//       if(forSessionStorage) {
//         sessionStorage.setItem(key,item);
//       } else {
//         localStorage.setItem(key,item);
//       }
//     }
//   } catch(e) {
//     consoleError('setCache - Error: '+convertToString(e));
//   }
// }

// function getCache(key,deltaInMilliseconds) {
//   if(typeof Storage !== "undefined") {
//     let data=sessionStorage.getItem(key);
//     let isTakenFromSession=true;

//     if(!data) {
//       data=localStorage.getItem(key);
//       isTakenFromSession=false;
//     }

//     if(data) {
//       data=JSON.parse(data);
//       let isExpired=deltaInMilliseconds < ((new Date().getTime())-data.timestamp);

//       debugLog(key+' - found'+(isTakenFromSession?' in session':'')+'!'+(isExpired?' But expired '+(((new Date().getTime())-data.timestamp)/1000)+ 'seconds ago, and will be deleted...':''));

//       if(isExpired) {
//         if(isTakenFromSession) {
//           sessionStorage.removeItem(key);
//         } else {
//           localStorage.removeItem(key);
//         }
//       }
//       return {
//         value: data.value,
//         isExpired: isExpired,
//         timestamp: new Date(data.timestamp),
//       }
//     } else {
//       debugLog(key+' - not found');
//       return null;
//     }
//   } else {
//     debugLog(key+' - Storage not supported');
//     return null;
//   }
// }

function convertToString(value: unknown, shouldAddSpaceToJson = true): string {
  const isError = (obj: unknown) => {
    return Object.prototype.toString.call(obj) === '[object Error]';
    // return obj && obj.stack && obj.message && typeof obj.stack === 'string'
    //        && typeof obj.message === 'string';
  };

  try {
    switch (typeof value) {
      case 'string':
      case 'boolean':
      case 'number':
      default:
        return '' + value;

      case 'undefined':
        return '';

      case 'object':
        if (value == null) {
          return '';
        } else if (isError(value)) {
          const error1: Error = value as Error;
          return error1.stack || error1.message;
        } else {
          if (shouldAddSpaceToJson) {
            return json_stringify_safe(value, null, 2);
          } else {
            return json_stringify_safe(value);
          }
        }
    }
  } catch (e) {
    return '' + value;
  }
}

function isNumeric(n: unknown): boolean {
  return !isNaN(parseFloat('' + n)) && isFinite(Number(n));
}

export default {
  isDebug,
  debugLog,
  consoleError,
  // setCache,
  // getCache,
  convertToString,
  isNumeric,
};
