import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import win from '../utils/win_util';

const fb_secrets = JSON.parse(process.env.GATSBY_FB_SECRETS || process.env.FB_SECRETS || '{}');

const config = {
  apiKey: fb_secrets.apiKey || 'AIzaSyDzuIb-YX5vIyvDIc5dVkwq0SMc7zBUbis',
  authDomain: fb_secrets.authDomain || 'apiprofit-dashboard.firebaseapp.com',
  projectId: fb_secrets.projectId || 'apiprofit-dashboard',
  appId: fb_secrets.appId || '1:599967986019:web:bce9863d733df246e86356',
};

let app: FirebaseApp | undefined;
let auth: Auth;

function initFirebase() {
  if (!app) {
    app = initializeApp(config);
    auth = getAuth(app);
  }
}

if (win) {
  initFirebase();
}

export { app, auth }; // export any other services as needed
export default app;
