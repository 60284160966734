/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { FirebaseAuthProvider } from './src/handlers/firebaseAuthProvider';

export function wrapPageElement({ element, props }) {
  return <FirebaseAuthProvider {...props}>{element}</FirebaseAuthProvider>;
}
