import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import utils from '../utils/utils';
import { Helmet } from 'react-helmet';
import FirebaseAPIs from '../handlers/firebaseAPIs';
import { Button } from 'react-bootstrap';
import * as styles from '../styles/login.module.css';
interface ICurrentProps {}

interface ICurrentState {
  username: string;
  password: string;
  error: string;
  isForgotPasswordLayout: boolean;
}

export default function Login(_props: ICurrentProps) {
  const [state, setState] = React.useState<ICurrentState>({
    username: '',
    password: '',
    error: '',
    isForgotPasswordLayout: false,
  });

  async function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    const email: string = state.username;
    const password: string = state.password;
    const isForgotPasswordMode: boolean = state.isForgotPasswordLayout;

    try {
      if (isForgotPasswordMode) {
        await FirebaseAPIs.sendResetPasswordEmail(email);
        alert(`Please check your inbox for further instructions`);
        setState(prev => ({
          ...prev,
          isForgotPasswordLayout: false,
        }));
      } else {
        const user = await FirebaseAPIs.login(email, password).catch(err1 => {
          console.error('ERROr1: ' + err1);
          setState(prev => ({
            ...prev,
            error: '' + err1,
          }));
        });
      }
      // console.log(`user=${JSON.stringify(user,null,2)}`);
    } catch (err2) {
      console.error(`ERROr2: ${utils.convertToString(err2)}`);
      const message =
        err2 && typeof err2 === 'object' && 'message' in err2
          ? (err2 as { message: string }).message
          : utils.convertToString(err2);
      setState(prev => ({
        ...prev,
        error: message,
      }));
    }
  }

  function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, username: event.target.value }));
  }

  function passwordChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, password: event.target.value }));
  }

  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* for="phone" */}

      <div className={styles.formContainer}>
        <form className={styles.form}>
          <label className="form-label mt-2">Email</label>
          <input className="form-control" type="text" onChange={emailChangeHandler} placeholder="user@domain.com" />
          <label className="form-label mt-2" hidden={state.isForgotPasswordLayout}>
            Password
          </label>
          <input
            className="form-control"
            type={'password'}
            onChange={passwordChangeHandler}
            hidden={state.isForgotPasswordLayout}
            placeholder="Password"
          />
          <Button
            className="mt-3"
            onClick={handleClick}
            disabled={!state.username || !(state.isForgotPasswordLayout || state.password)}
          >
            {state.isForgotPasswordLayout ? 'Send a reset-password email' : 'Login'}
          </Button>
        </form>
        <br hidden={state.isForgotPasswordLayout} />
        <a
          className={styles.link}
          hidden={state.isForgotPasswordLayout}
          onClick={() => {
            setState(prev => ({
              ...prev,
              isForgotPasswordLayout: true,
            }));
          }}
        >
          Forgot password?
        </a>
        <br hidden={!state.isForgotPasswordLayout} />
        <a
          className={styles.link}
          onClick={() => {
            setState(prev => ({ ...prev, isForgotPasswordLayout: false }));
          }}
          hidden={!state.isForgotPasswordLayout}
        >
          I remember the password!
        </a>
        <br />
        <a className={styles.link} href="/">
          Back to homepage
        </a>
        <p style={{ color: 'red' }}>{state.error}</p>
      </div>
    </div>
  );
}
