import * as React from 'react';
import { useEffect, useState, useContext, createContext, FunctionComponent } from 'react';
import Login from '../components/login';
import win from '../utils/win_util';
import { auth } from './firebaseInit';
import firebaseAPIs from './firebaseAPIs';
import Loading from '../components/loading';
import { User } from 'firebase/auth';

type IContext = typeof firebaseAPIs & {
  user: User | null;
  loading: boolean;
};

const FirebaseAuthContext = createContext<IContext>({
  user: null,
  loading: true,
  ...firebaseAPIs,
});

function isPagePublic(children: React.ReactNode): boolean {
  if (
    children &&
    typeof children === 'object' &&
    'type' in children &&
    children.type &&
    typeof children.type === 'function'
  ) {
    const _isPagePublic: boolean = Boolean(
      'isPagePublic' in children.type && (children.type as { isPagePublic: boolean }).isPagePublic
    );

    const _isDev404Page: boolean = Boolean(
      'name' in children.type && (children.type as unknown as { name: string }).name === 'Dev404Page'
    );

    return _isPagePublic || _isDev404Page;
  } else {
    return false;
  }
}
interface FirebaseAuthProviderProps {
  children: React.ReactNode;
}
export const FirebaseAuthProvider: FunctionComponent<FirebaseAuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (win) {
      const cancelAuthListener = auth?.onIdTokenChanged(user => {
        // console.log(`user=${JSON.stringify(user, null, 2)}`);
        setUser(user);
        setLoading(false);
      });

      return () => {
        cancelAuthListener ? cancelAuthListener() : () => {};
      };
    } else {
      return;
    }
  }, []);

  if (loading) return <Loading />;
  if (user || isPagePublic(children)) {
    return (
      <FirebaseAuthContext.Provider
        value={{
          user,
          loading,
          ...firebaseAPIs,
        }}
      >
        {children}
      </FirebaseAuthContext.Provider>
    );
  } else {
    return <Login />;
  }
};

export function useFirebaseAuthContext() {
  return useContext(FirebaseAuthContext);
}
